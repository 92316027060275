<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>XML Configurations</h1>
      <div class="btns-container">
        <VasionButton
          v-if="configurationsAvailable"
          id="new-configuration-header-button"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="newConfigurationClick"
        >
          New Configuration
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="configurationsAvailable && tableData && !loadingConfigurations" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableRowsOrdered"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          :supportSorting="true"
          @vasion-column-sort="sortColumn"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else-if="!loadingConfigurations" class="no-items-div">
        <VasionEmptyForm />
        <div class="vasion-large-message">
          {{ greetingMessage }}
        </div>
        <div class="vasion-page-subheader instructional-message-div">
          It feels a little empty, create a XML Configuration to get started!
        </div>
        <div class="button-div">
          <VasionButton
            id="new-configuration-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="newConfigurationClick"
          >
            <span class="vasion-button-plus">+</span>XML Configuration
          </VasionButton>
        </div>
      </div>
      <md-dialog id="confirmConfigurationDelete" :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDialog()" @yesButtonClick="deleteConfigurationAndToggle()" />
      </md-dialog>
      <Loading
        class="vasion-loading-indicator"
        :active.sync="loadingConfigurations"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />

      <VasionSnackbar
        id="xml-fields-snack"
        :showSnackbarBool="showSnackbar"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
    </div>
  </div>
</template>

<script>
import { OrderByEnum } from '@/enums/orderByEnum.js'
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { formatDateTime } from '../../store/helperModules/common.module';

export default {
  name: 'XMLFields',
  components: {
    Loading,
  },
  data: function () {
    return {
      ColumnValues: [],
      configurations: [],
      deleteConfigurationID: 0,
      deleteConfigurationName: '',
      displayConfigurationSelection: false,
      greetingMessage: 'Hello!',
      headerCheckboxToggled: false,
      hiddenColumns: ['configurationID'],
      loadingConfigurations: true,
      loaderBackgroundColor,
      loaderColor,
      showDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortingColumn: 1,
      sortingDirection: OrderByEnum.Ascending,
      tableData: {},
    }
  },
  computed: {
    configurationsAvailable() { return this.configurations.length > 0 },
    deletePromptMessage() { return `Are you sure you want to delete "${this.deleteConfigurationName}"?` },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    tableRowsOrdered() {
      if (!(this.tableData.Rows && this.tableData.Rows.Values)){
        return []
      }

      function compareCellData(column) {
        return function (ItemA, ItemB) {
          let ValueA = ItemA.Values[column] || ''
          let ValueB = ItemB.Values[column] || ''
          if (ValueA < ValueB) {
            return -1
          }
          if (ValueA > ValueB) {
            return 1
          }
          return 0
        }
      }

      let tableRows = this.tableData.Rows.Values
      if (this.sortingColumn >= 0) {
        tableRows.sort(compareCellData(this.sortingColumn))
        if (this.sortingDirection === OrderByEnum.Descending) {
          tableRows.reverse()
        }
      }
      return tableRows
    },
  },
  async created() {
    await this.loadViewData()
  },
  methods: {
    async deleteConfigurationAndToggle() {
      this.loadingConfigurations = true
      this.toggleDialog()
      const response = await this.$store.dispatch('admin/deleteXmlConfiguration', this.deleteConfigurationID)
      await this.loadViewData()

      if (response?.data?.ResultStatus === 1) {
        this.snackbarImage = true
        this.snackbarTitle = 'Delete Successful'
        this.snackbarSubTitle = `Successfully deleted "${this.deleteConfigurationName}".`
      } else {
        this.snackbarImage = false
        this.snackbarTitle = 'Error Deleting'
        this.snackbarSubTitle = `There was an error deleting "${this.deleteConfigurationName}".\nPlease contact your administrator if the problem persists.`
      }
      this.showSnackbar = true
      this.deleteConfigurationID = 0
      this.deleteConfigurationName = ''
    },
    async editConfiguration(configurationData) {
      this.$store.commit('admin/setEditXmlConfig', configurationData)

      this.$router.push({ name: 'EditXmlConfiguration'})
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-field-mapping-button':
            this.editConfiguration(this.configurations.find(config => config.id === id))
            break;
          case 'delete-field-mapping-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.setDeleteValues(id, name)
            break;
          default:
            break;
        }
      }
    },
    newConfigurationClick() {
      this.$store.commit('admin/setEditXmlConfig', null)

      this.$router.push({ name: 'EditXmlConfiguration'})
    },
    refreshTable() {
      let configurationTableData = {
        Rows: {
          Values: [],
        }
      }

      this.tableData.Rows = configurationTableData

      configurationTableData.Rows.Values = this.configurations.map((configurationElement) => {
        const dataRow = [
          configurationElement.id,
          configurationElement.name,
          configurationElement.createdByName,
          configurationElement.createdDate ? formatDateTime(configurationElement.createdDate, 'date') : '',
          configurationElement.modifiedDate ? formatDateTime(configurationElement.modifiedDate, 'date') : '',
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.tableData = configurationTableData
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editConfiguration(this.configurations.find(config => config.id === data.Values[0]))
    },
    async loadViewData() {
      this.ColumnValues = [
        'configurationID',
        'Configuration Name',
        'Created By',
        'Created Date',
        'Modified Date',
        '_VasionEllipsisButton_',
      ]
      const emptyAppGreetingPromise = this.$store.dispatch('common/getEmptyAppGreeting')
      const xmlConfigListPromise = this.$store.dispatch('admin/getXmlConfigurationList')

      this.greetingMessage = await emptyAppGreetingPromise
      this.configurations = (await xmlConfigListPromise).configurations
      this.loadingConfigurations = false

      this.$store.dispatch('attributeForm/getForms')
      this.refreshTable()
      await this.$store.dispatch('security/setEllipsisButtonConfig', 'xml-field-mapping')
    },
    setDeleteValues(configurationID, configurationName) {
      this.deleteConfigurationID = configurationID
      this.deleteConfigurationName = configurationName
      this.toggleDialog()
    },
    sortColumn(payload) {
      this.sortingColumn = this.ColumnValues.findIndex((element) => element === payload.columnName)
      this.sortingDirection = payload.ascending ? OrderByEnum.Ascending : OrderByEnum.Descending
    },
    toggleDialog() { this.showDialog = !this.showDialog },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}

.instructional-message-div {
  margin-top: 24px;
}

.no-items-div {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
